import React from 'react';
import { Link } from 'gatsby';
import { Button, ContactForm } from 'components';

import { styled } from 'styles';

const TopSection404 = () => {
  return (
    <StyledTopSection404 className="not-found-section">
      <div className="not-found-wrapper">
        <div className="not-found">
          <h1 className="not-found__title">Page doesn’t exist</h1>
          <p className="not-found__description">Looks like something went wrong.</p>
          <Link to="/" className="not-found__button-link">
            <Button className="not-found__button" primary onClick={() => <Link to="/" />}>
              Back to Main page
            </Button>
          </Link>
        </div>
        <ContactForm source="404 Page" />
      </div>
    </StyledTopSection404>
  );
};

const StyledTopSection404 = styled.div`
  padding: 108px 16px 88px;
  display: flex;
  justify-content: center;
  .not-found-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    max-width: 990px;
  }
  .not-found {
    padding: 116px 0 0;
    flex: 1;
    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
      margin: 0 0 20px;
    }
    &__description {
      font-size: 16px;
      line-height: 19px;
      margin: 0 0 32px;
    }
    &__button {
      flex-shrink: 0;
      font-size: 12px;
      line-height: 14px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 55px 16px 84px;
    .not-found-wrapper {
      flex-direction: column;
      max-width: 100%;
      align-items: center;
    }
    .not-found {
      padding: 0 0 72px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 37px 16px 0;
  }
`;

export default TopSection404;
